<template>
  <section class="content-recupery-password">
    <div class="content-recupery-password-itens container">
      <router-link tag="a" :to="{name: 'login'}" class="content-recupery-password-itens--logo">
        <img class="img-fluid" src="@/assets/img/logo-grupo-nabeiro.png" alt="logo grupo nabeiro" title="logo grupo nabeiro"/>
      </router-link>
      <form ref="formRecover" @submit.prevent="onRecoverPass" class="content-recupery-password-itens-form">
        <div class="content-recupery-password-itens-form--title">
          <h1>Recuperar contraseña</h1>
        </div>
        <div class="content-recupery-password-itens-form-inputs">
          <div class="row">
            <div class="col-lg-6">
              <div class="content-input">
                <input type="text" name="email" v-model="email" placeholder="E-mail" />
                <span v-if="errors.email" v-bind:class="{ 'has-error': errors.email }">{{ errors.email }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content-recupery-password-itens-form-btns">
          <button class="btn--black" type="submit">
            <p>Recuperar contraseña</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9"><path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)"/></svg>
          </button>
          <router-link class="btn--link" tag="a" :to="{name: 'login'}">Voltar</router-link>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';

export default {
  data(){
    return{
      email: '',
      errors: {
        email: '',
      }
    }
  },
  methods:{
    ...mapActions('App', ['loading']),        
    async onRecoverPass(el){  
      this.formValidate();

      if (this.errors.email) {
        return;
      }          
      let formData = new FormData(this.$refs.formRecover)

      this.loading(true);
      await axios({
          method: 'POST',
          url: '/api/v1/campaign/user/recover-pass',
          data: formData
      }).then(response => response.data)
      .then((data) => {                
          this.loading(false);
          this.$notify({                    
              type: "success",
              text: data.message,
              duration: 10000
          });
      })
      .catch((error) => {
          this.loading(false);
          this.$notify({                    
              type: "warn",
              text: error.response.data.message,
              duration: 10000
          });
      })
    },
    formValidate() {
      this.validateEmail();
    },
    validateEmail() {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!this.email) {
        this.errors.email = 'Por favor complete este campo.'
      } else if (!regex.test(this.email)) {
        this.errors.email = 'Introduzca un correo electrónico válido.'
      } else {
        this.errors.email = ''
      }
    },
  }
}
</script>

<style lang="scss">
@import './index.scss';
.has-error{
  color: var(--red);
  padding: 7px 0px;
  border-radius: 15px;
  font-size: 14px;
  display: flex;
}
</style>
